'use strict';
import Ready from './ready.js';

/**
 * Urls:
 *   https://www.openstreetmap.org/?mlat=48.77634&mlon=9.17528#map=19/48.77634/9.17528
 *   https://www.openstreetmap.org/#map=19/48.77634/9.17528
 *   https://www.openstreetmap.org/?mlat=48.77634&mlon=9.17528
 *   geo:48.77634,9.17528?z=19
 *
 * <div data-leaflet data-url="geo:48.77634,9.17528?z=19" title="Kronprinzen Strasse" style="height: 450px;" class="rounded"></div>
 */
class Leaflet {
    constructor() {
        this.translation = {
            en: {},
            de: {
                'A JS library for interactive maps': 'Eine JS-Bibliothek für interaktive Karten',
                'OpenStreetMap contributors': 'OpenStreetMap-Mitwirkende',
                'Tile style from': 'Kachelstil von',
                'hosted by': 'gehostet von',
                'France': 'Frankreich',
                'Default': 'Standard',
                'Cyclist map': 'Radfahrerkarte',
                'Markings': 'Markierungen',
                'Open in': 'Öffne in',
            }
        };
    }

    async initialize() {
        let instance = this;
        let maps = document.querySelectorAll('[data-leaflet]');
        if (maps.length > 0) {
            import(
                /* webpackChunkName: "leaflet" */
                'leaflet/dist/leaflet.js'
            );
            const {default: leafletIconUrl} = await import(
                /* webpackChunkName: "leaflet" */
                'leaflet/dist/images/marker-icon.png'
            );
            const {default: leafletIconRetinaUrl} = await import(
                /* webpackChunkName: "leaflet" */
                'leaflet/dist/images/marker-icon-2x.png'
            );
            const {default: leafletShadowUrl} = await import(
                /* webpackChunkName: "leaflet" */
                'leaflet/dist/images/marker-shadow.png'
            );

            L.Marker.prototype.options.icon = L.icon({
                iconUrl: leafletIconUrl,
                iconRetinaUrl: leafletIconRetinaUrl,
                shadowUrl: leafletShadowUrl,
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
                shadowSize: [41, 41]
            });

            maps.forEach(map => {
                if (map.dataset.hasOwnProperty('url')) {
                    instance.createMapByUrl(map);
                }
            });
        }
    }

    translate(key) {
        let language = navigator.language;
        if (this.translation.hasOwnProperty(language) && this.translation[language].hasOwnProperty(key)) {
            return this.translation[language][key];
        } else if (this.translation.hasOwnProperty('en') && this.translation.en.hasOwnProperty(key)) {
            return this.translation.en[key];
        }
        return key;
    }

    getSettingsByGeoUrl(url, settings) {
        let urlParts = url.substring(4).split('?')
        let coordinates = urlParts[0].split(',');
        settings.lat = parseFloat(coordinates[0]);
        settings.lon = parseFloat(coordinates[1]);

        let search = urlParts[1] ?? '';
        if (search) {
            let urlSearch = (new URL('https://temp.com/?' + search));
            if (urlSearch.searchParams.get('z')) {
                settings.zoom = parseInt(urlSearch.searchParams.get('z'), 10);
            }
        }
        return settings;
    }

    getSettingsByOpenStreetMapUrl(url, settings) {
        url = new URL(url);

        if (url.hash && url.hash !== '') {
            let exprHash = new RegExp('^#map=(?<zoom>[0-9]+)\/(?<lat>[0-9]+.[0-9]+)\/(?<lon>[0-9]+.[0-9]+)$', 'i');
            let {groups: {zoom, lat, lon}} = exprHash.exec(url.hash);
            settings.lat = parseFloat(lat);
            settings.lon = parseFloat(lon);
            settings.zoom = parseInt(zoom, 10);
        }

        if (url.searchParams && url.searchParams.get('mlat')) {
            settings.lat = parseFloat(url.searchParams.get('mlat'));
        }
        if (url.searchParams && url.searchParams.get('mlon')) {
            settings.lon = parseFloat(url.searchParams.get('mlon'));
        }
        return settings;
    }

    createMapByUrl(map) {
        if (!(map.dataset.hasOwnProperty('url') && map.dataset.url !== '')) {
            return;
        }

        let url = map.dataset.url;

        let settings = {
            lat: 0.0,
            lon: 0.0,
            zoom: 17,
        };

        if (url.indexOf('geo:') === 0) {
            settings = this.getSettingsByGeoUrl(url, settings);
        } else {
            settings = this.getSettingsByOpenStreetMapUrl(url, settings);
        }

        // Create Layers
        let layerSettings = {
            maxZoom: 19,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">' + this.translate('OpenStreetMap contributors') + '.</a>',
        };
        let layerDefault = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            ...layerSettings,
        });
        let layerCycle = L.tileLayer('https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png', {
            ...layerSettings,
            attribution: layerSettings.attribution + ' ' + this.translate('Tile style from')
                + ' <a href="https://www.cyclosm.org/" target="_blank">CyclOSM</a> ' + this.translate('hosted by')
                + ' <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap ' + this.translate('France') + '</a>',
        });

        // Create marker and marker group
        let marker = L.marker([settings.lat, settings.lon]);
        if (map.title) {
            marker.bindPopup(map.title);
        }
        let markerGroup = L.layerGroup([
            marker,
        ]);

        // Create map
        let mapLeaflet = L.map(map, {
            scrollWheelZoom: false,
            layers: [layerDefault, markerGroup],
        }).setView([settings.lat, settings.lon], settings.zoom);

        // Change attribution
        let mapUrl = 'https://www.openstreetmap.org/?mlat=' + settings.lat + '&mlon=' + settings.lon
            + '#map=' + settings.zoom + '/' + settings.lat + '/' + settings.lon;
        mapLeaflet.attributionControl.setPrefix(
            '<a href="' + mapUrl + '" title="' + this.translate('Open in') + ' OpenStreetMap" target="blank">' + this.translate('Open in') + ' OpenStreetMap</a>'
            + ' | <a href="https://leafletjs.com" title="' + this.translate('A JS library for interactive maps') + '" target="blank">Leaflet</a>'
        );

        // Add Controls
        let baseLayers = {};
        baseLayers[this.translate('Default')] = layerDefault;
        baseLayers[this.translate('Cyclist map')] = layerCycle;

        let overlays = {};
        overlays[this.translate('Markings')] = markerGroup;

        L.control.layers(baseLayers, overlays).addTo(mapLeaflet);
    }
}

Ready(function () {
    const leaflet = new Leaflet();
    leaflet.initialize();
});
