'use strict';

async function loadSlick() {
    await import(
        /* webpackChunkName: "slick" */
        'slick-carousel/slick/slick.js'
    );
}

class Slick {
    constructor() {
        this.settings = {
            speed: 600,
            slidesToShow: 1,
            slidesToScroll: 1,

            infinite: true,
            dots: true,
            draggable: true,
            cssEase: 'linear',
            fade: true,

            autoplay: true,
            autoplaySpeed: 2000,

            pauseOnHover: true,
            pauseOnDotsHover: true,
        };
        this.initialize();
    }

    initialize() {
        let instance = this;
        let sliders = document.querySelectorAll('.slickSlider, .slickHero, .slickFlexible');
        if (sliders.length > 0) {
            loadSlick().then(() => {
                sliders.forEach((slider) => {
                    if (slider.classList.contains('slickHero')) {
                        instance.createSlider(slider, {
                            autoplaySpeed: 10000,
                            prevArrow: '<div class="prevArrow"><i class="fas fa-chevron-left"></i></div>',
                            nextArrow: '<div class="nextArrow"><i class="fas fa-chevron-right"></i></div>'
                        });
                    } else {
                        instance.createSlider(slider);
                    }
                });
            });
        }
    }

    getSettingsBySlider(slider) {
        let settings = this.settings;
        if (slider.dataset.hasOwnProperty('settings')) {
            try {
                let jsonSettings = slider.dataset.settings;
                if (jsonSettings) {
                    settings = {...settings, ...JSON.parse(slider.dataset.settings)};
                }
            } catch (exception) {
                console.error('Could not parse slick slider settings! ' + exception.toString());
            }
        }
        return settings;
    }

    createSlider(slider, overwriteSettings) {
        if (!overwriteSettings) {
            overwriteSettings = {};
        }
        $(slider).slick({...this.getSettingsBySlider(slider), ...overwriteSettings});
    }
}

jQuery(function($) {
    new Slick();
});
