'use strict';

export default function(callback) {
    if (document.readyState === 'interactive' || document.readyState === 'complete') {
        // Document is already ready, call the callback directly
        callback();
    } else if (document.addEventListener) {
        // All modern browsers to register DOMContentLoaded
        document.addEventListener('DOMContentLoaded', callback);
    } else if (document.attachEvent) {
        // Old IE browsers
        document.attachEvent('onreadystatechange', function() {
            if (document.readyState !== 'loading') {
                callback();
            }
        });
    } else {
        // If everyone fails, just do it
        callback();
    }
}
