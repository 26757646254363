'use strict';
import Ready from './ready.js';
import Global from './global.js';

async function loadCookieConsent() {
    await import(
        /* webpackChunkName: "cookieconsent" */
        'cookieconsent/build/cookieconsent.min.js'
    );
}

class CookieConsent {
    constructor() {
        if (!window.navigator.cookieEnabled) {
            return;
        }

        let instance = this;
        this.global = new Global();

        this.selectors = '#btn-cookieconsent-revokeChoice';
        this.cookieName = 'cookieconsent_status';
        this.cookieconsentPopup = null;

        let statusStorage = localStorage.getItem(this.cookieName);
        let statusCookie = this.global.getCookie(this.cookieName);
        if (this.global.localStorageExists() && statusStorage && statusCookie === undefined) {
            statusCookie = statusStorage;
            this.global.setCookie(this.cookieName, statusCookie);
        }

        if (statusCookie !== undefined && statusCookie === 'deny') {
            this.global.removeAllCookies([this.cookieName]);
        }

        if (document.querySelectorAll(this.selectors).length > 0 || statusCookie === undefined) {
            loadCookieConsent().then(function () {
                instance.initialize();
                instance.revokeChoice();
            });
        }
    }

    initialize() {
        let instance = this;
        window.cookieconsent.initialise({
            palette: {
                popup: {
                    background: '#252e39'
                },
                button: {
                    background: '#14a7d0'
                }
            },

            // None -> Just tell users that we use cookies
            // type: 'opt-out', // Let users opt out of cookies (Advanced)
            // type: 'opt-in', // Ask users to opt into cookies (Advanced)

            layout: 'basic',
            theme: 'edgeless',
            position: 'bottom-right',
            content: {
                header: 'Cookies auf der Website!',
                message: 'Diese Website verwendet Cookies, um sicherzustellen, dass Sie die beste Erfahrung auf unserer Website erzielen.',
                dismiss: '<i class="fas fa-cookie"></i> &nbsp; Cookies erlauben',
                policy: 'Cookie-Richtlinie',
                allow: '<i class="fas fa-cookie"></i> &nbsp; Cookies erlauben',
                deny: '<i class="fas fa-cookie-bite"></i> &nbsp; Ablehnen',
                link: 'Mehr erfahren'
                // href: 'https://netslum.de',
            },
            onStatusChange: function(status, chosenBefore) {
                if (instance.global.localStorageExists()) {
                    localStorage.setItem(instance.cookieName, status);
                }
                if (status !== undefined && status === 'deny') {
                    instance.global.removeAllCookies([instance.cookieName]);
                }
            },
            onRevokeChoice: function() {
                if (instance.global.localStorageExists()) {
                    localStorage.removeItem(instance.cookieName);
                }
            }
        }, function (popup) {
            instance.cookieconsentPopup = popup;
        });
    }

    revokeChoice() {
        let instance = this;
        let revokeChoice = document.getElementById('btn-cookieconsent-revokeChoice');
        if (revokeChoice && typeof revokeChoice !== 'undefined') {
            revokeChoice.onclick = function (event) {
                instance.cookieconsentPopup.revokeChoice();
            };
        }
    }
}

Ready(function () {
    new CookieConsent();
});
